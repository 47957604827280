import { FunctionComponent } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Paper,
} from "@mui/material";

import { Cell, HeadCell } from "..";
import { formatDateTime } from "../../utils";

type Props = Time & {
  scheduled_time_of_departure: Date;
  aircraft_registration: string;
  flight_identifier: string;
};

export const InfoTable: FunctionComponent<Props> = ({
  scheduled_time_of_departure,
  aircraft_registration,
  flight_identifier,
}) => (
  <TableContainer component={Paper} sx={{ marginBottom: "20px" }}>
    <Table aria-label="info table">
      <TableHead>
        <TableRow>
          <HeadCell>
            <b>Info</b>
          </HeadCell>
          <HeadCell></HeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <Cell component="th" scope="row">
            Date:
          </Cell>
          <Cell>{formatDateTime(scheduled_time_of_departure)}</Cell>
        </TableRow>

        <TableRow>
          <Cell component="th" scope="row">
            Registration:
          </Cell>
          <Cell>{aircraft_registration}</Cell>
        </TableRow>

        <TableRow>
          <Cell component="th" scope="row">
            Callsign:
          </Cell>
            <Cell>{flight_identifier}</Cell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);
