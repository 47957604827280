import styled, { createGlobalStyle } from "styled-components";
import { Link } from 'react-router-dom';
import { OverridableComponent } from "@mui/material/OverridableComponent";
import {
  Button,
  ButtonTypeMap,
  Container,
  TableCell,
  TableRow,
  Typography,
  TypographyTypeMap,
  tableCellClasses,
} from "@mui/material";
import LoginBackground from "../assets/background.jpg";
import Background from "../assets/background2.jpg";

export const GlobalStyles = createGlobalStyle`
 * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Roboto', monospace; 
  }

  a {
    color: inherit;
  }

  img {
    max-width: 100%;
  }

  iframe {
    border: 0;
  }

  .marker-label {
    margin-left: calc(50% - 7px);
    margin-bottom: 6px;
    text-shadow: -1px 1px 1px #ffffff;
    font-weight: 500;
  }

  .label-purple {
    color: #560063 !important;
  }

  .label-cyan {
    color: #007b82 !important;
  }

  .label-pink {
    color: #ff278d !important;
  }

  body {
    background-color: #fff;
  }

  body::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.15;
    background-image: url(${Background});
    background-size: cover;
  }

  #root {
    z-index: 1;
    position: relative;
  }

  div[name="warning-box"] {
    position: absolute;
    bottom: 0;
  }

  .jsoneditor {
    border: 0;
  }

  .jsoneditor-menu {
    display: none;
  }

  div.jsoneditor-outer.has-main-menu-bar {
    padding-top: 0;
    margin-top: 0;
  }

  .zammad-form-modal {
    .zammad-form-modal-backdrop {
      background: rgba(0, 0, 0, 0.5);
    }

    .zammad-form-modal-body {
      border-radius: 4px;
      width: calc(100% - 32px);
      max-width: 536px;
      padding: 32px;
    }

    h2 {
      margin: 0;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      font-size: 2.125rem;
      line-height: 1.235;
      letter-spacing: 0.00735em;
      padding-bottom: 28px;
      color: rgba(0, 0, 0, 0.87);
    }

    .btn {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      font-size: 0.9375rem;
      line-height: 1.75;
      letter-spacing: 0.02857em;
      text-transform: uppercase;
      min-width: 64px;
      padding: 8px 22px;
      border-radius: 4px;
      border: none;
      background: #000;
      color: #fff;
      width: 200px;
      height: 56px;
      display: block;
      margin: 24px auto 8px;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      cursor: pointer;
    }

    .zammad-form-control {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.87);
      background: none;
      height: 56px;
      margin: 0;
      display: block;
      min-width: 0;
      width: 100%;
      padding: 16.5px 14px;
      border: 1px solid #c5c5c5;
      border-radius: 4px;
    }

    textarea.zammad-form-control {
      height: auto;
    }

    .zammad-form-group {
      position: relative;
      margin-bottom: 16px;

      label {
        position: absolute;
        padding: 0 6px;
        top: -7px;
        left: 9px;
        background: #fff;
        font-size: 0.75em;
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .zammad-form-thankyou {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.87);
    }
  }
`;

export const Page = styled.div`
  padding: 40px 0;

  @media (max-width: 600px) {
    padding: 20px 0;
  }
`;

export const FiltersWrapper = styled.div`
  padding: 20px 0;

  @media (max-width: 600px) {
    padding: 0;
  }
`;

export const Heading: OverridableComponent<TypographyTypeMap> = styled(
  Typography
)`
  padding-bottom: 30px;

  @media (max-width: 600px) {
    padding-bottom: 10px;
    font-size: 1.6rem !important;
  }
`;

export const CusotmIconButton: OverridableComponent<ButtonTypeMap> = styled(Button)`
  margin-right: 10px !important;

  @media (max-width: 600px) {
    padding: 7px 13px 7px 21px !important;
  }
`;

export const Cell: OverridableComponent<any> = styled(TableCell)`
  padding: 10px !important;
`;

export const HeadCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#000",
    color: "#FFF",
    padding: "10px !important",
    borderBottom: "1px solid #000",
    cursor: "default",
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: "#000",
    color: "#FFF",
    padding: "10px !important",
    borderBottom: "1px solid #000",
  },
}));

export const GrayRow: OverridableComponent<any> = styled(TableRow)`
  background-color: #f5f5f5;
`;

export const ButtonText = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`;

export const StyledContainer: OverridableComponent<any> = styled(Container)`
  background-image: url(${LoginBackground});
  background-position: center;
  background-size: cover;
  max-width: 100%;
  height: calc(100vh - 64px);
  overflow: auto;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;

  ::before {
    /* Create the pseudo-element to hold the pattern */
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: radial-gradient(#000000 0.6px, #22222240 0.6px),
      radial-gradient(#000000 0.6px, #22222240 0.6px);
    background-size: 8px 8px;
    background-position: 0 0, 4px 4px;
  }
`;

export const Clear = styled.div`
  width: 27px;
  height: 27px;
  position: absolute;
  top: 15px;
  right: 40px;
  padding: 4px;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 0;

  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const DatePickerWrapper = styled.div`
  position: relative;

  :hover > ${Clear} {
    opacity: 1;
  }
`;

export const WhiteBackground = styled.div`
  background-color: #ffffff;
  min-height: calc(100vh - 64px);
  height: calc(100% - 64px);
`;

export const InlineLink: OverridableComponent<any> = styled(Link)`
  display: inline-block;
`;
