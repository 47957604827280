import {  FunctionComponent } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import { Cell, HeadCell } from "../../";

const DelaysTable: FunctionComponent<{ delays: Delay[] }> = ({ delays }) => {
  return (
    <TableContainer component={Paper} sx={{ marginBottom: "20px" }}>
      <Table aria-label="Delay Table">
        <TableHead>
          <TableRow>
            <HeadCell>
              <b>Minutes</b>
            </HeadCell>
            <HeadCell>
              <b>Reason</b>
            </HeadCell>
            <HeadCell>
              <b>Remark</b>
            </HeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {delays.map((delay) => (
            <TableRow key={delay.id}>
              <Cell>{delay.minutes || "N/A"}</Cell>
              <Cell>{delay.reason || "N/A"}</Cell>
              <Cell>{delay.remark || "N/A"}</Cell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DelaysTable;
