import {  FunctionComponent } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import { Cell, HeadCell } from "../../";
import { formatTime } from "../../../utils";

const DeicingsTable: FunctionComponent<{ deicings: Deicing[] }> = ({ deicings }) => {
  return (
    <TableContainer component={Paper} sx={{ marginBottom: "20px" }}>
      <Table aria-label="Deicing Table">
        <TableHead>
          <TableRow>
            <HeadCell>
              <b>Time</b>
            </HeadCell>
            <HeadCell>
              <b>Deicing Type</b>
            </HeadCell>
            <HeadCell>
              <b>Deicing Mix</b>
            </HeadCell>
            <HeadCell>
              <b>Quantity</b>
            </HeadCell>
            <HeadCell>
              <b>Brand</b>
            </HeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {deicings.map((deicing) => (
            <TableRow key={deicing.id}>
              <Cell>{formatTime(deicing.deicing_timestamp)}</Cell>
              <Cell>{deicing.deicing_type || "N/A"}</Cell>
              <Cell>{deicing.deicing_mix || "N/A"}</Cell>
              <Cell>{deicing.qty || "N/A"}</Cell>
              <Cell>{deicing.brand || "N/A"}</Cell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DeicingsTable;
