import {  FunctionComponent } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import { Cell, HeadCell } from "../../";

const ApproachesTable: FunctionComponent<{ approaches: Approach[] }> = ({ approaches }) => {
  return (
    <TableContainer component={Paper} sx={{ marginBottom: "20px" }}>
      <Table aria-label="Approach Table">
        <TableHead>
          <TableRow>
            <HeadCell>
              <b>Type</b>
            </HeadCell>
            <HeadCell>
              <b>Successful</b>
            </HeadCell>
            <HeadCell>
              <b>Day</b>
            </HeadCell>
            <HeadCell>
              <b>EFVS</b>
            </HeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {approaches.map((approach) => (
            <TableRow key={approach.id}>
              <Cell>{approach.type || "N/A"}</Cell>
              <Cell>
                {approach.successful ? (
                  <CheckOutlinedIcon sx={{ color: "green" }} />
                ) : (
                  <ClearOutlinedIcon sx={{ color: "red" }} />
                )}
              </Cell>
              <Cell>
                {approach.day ? (
                  <CheckOutlinedIcon sx={{ color: "green" }} />
                ) : (
                  <ClearOutlinedIcon sx={{ color: "red" }} />
                )}
              </Cell>
              <Cell>
                {approach.EFVS ? (
                  <CheckOutlinedIcon sx={{ color: "green" }} />
                ) : (
                  <ClearOutlinedIcon sx={{ color: "red" }} />
                )}
              </Cell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ApproachesTable;
