import { FunctionComponent, useState } from "react";
import { AppBar, Toolbar, Typography, Button, Container, Box, IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import useSignout from "../hooks/useSignout";
import useZammadForm from '../hooks/useZammadForm';

export const Navigation: FunctionComponent = () => {
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
  const userData = JSON.parse(localStorage.getItem('user') || "{}");
  const email = userData?.email;
  const isDashboardEnabled = userData?.is_dashboard_enabled;
  const isAdmin = userData?.role === "admin";

  const { handleSignout } = useSignout();

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const handleOpenNavMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(e.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  useZammadForm(email);

  return (
    <AppBar position="static">
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              letterSpacing: { xs: 2, md: ".3rem" },
              fontWeight: 700,
              color: "#fff !important",
              textDecoration: "none",
              flexGrow: 1,
            }}
          >
            smartEFB Flight Viewer
          </Typography>
          {isAuthenticated && (
            <>
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <>
                  <Button component="a" href="/flights" color="inherit">
                    Flights
                  </Button>
                  {!!isDashboardEnabled && (
                    <>
                      <Button component="a" href="/dashboard" color="inherit">
                        Dashboard
                      </Button>
                      <Button component="a" href="/fleets" color="inherit">
                        Fleets
                      </Button>
                    </>
                  )}
                  <Button component="a" href="/users" color="inherit">
                    Users
                  </Button>
                  {!!isAdmin && (
                    <Button component="a" href="/operators" color="inherit">
                      Operators
                    </Button>
                  )}
                  <Button component="a" className="show-zammad-form" color="inherit">
                    Support
                  </Button>
                  <Button color="inherit" onClick={handleSignout}>
                    Log out
                  </Button>
                </>
              </Box>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                sx={{ display: { xs: "flex", md: "none" }, pr: 0, pl: 0 }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                keepMounted
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                  '& .MuiPaper-root': {
                    width: '100%',
                    maxWidth: '100%',
                    borderRadius: '0 0 4px 4px',
                    left: '0 !important',
                    top: '56px !important',
                  },
                }}
              >
                <MenuItem onClick={handleCloseNavMenu} component="a" href="/flights">
                  <Typography textAlign="center">Flights</Typography>
                </MenuItem>
                {isDashboardEnabled && (
                  <MenuItem onClick={handleCloseNavMenu} component="a" href="/dashboard">
                    <Typography textAlign="center">Dashboard</Typography>
                  </MenuItem>
                )}
                <MenuItem onClick={handleCloseNavMenu} component="a" href="/users">
                  <Typography textAlign="center">Users</Typography>
                </MenuItem>
                <MenuItem className="show-zammad-form" onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Support</Typography>
                </MenuItem>
                <MenuItem onClick={() => {
                  handleSignout();
                  handleCloseNavMenu();
                }}>
                  <Typography textAlign="center">Log out</Typography>
                </MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
