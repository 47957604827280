import {  FunctionComponent } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import { Cell, HeadCell } from "../../";
import { formatTime } from "../../../utils";

const FuelingsTable: FunctionComponent<{ fuelings: Fueling[] }> = ({ fuelings }) => {
  return (
    <TableContainer component={Paper} sx={{ marginBottom: "20px" }}>
      <Table aria-label="Fueling Table">
        <TableHead>
          <TableRow>
            <HeadCell>
              <b>Time</b>
            </HeadCell>
            <HeadCell>
              <b>Fuel Uplift</b>
            </HeadCell>
            <HeadCell>
              <b>Fuel Before Uplift</b>
            </HeadCell>
            <HeadCell>
              <b>Fuel After Uplift</b>
            </HeadCell>
            <HeadCell>
              <b>Density</b>
            </HeadCell>
            <HeadCell>
              <b>Density Units</b>
            </HeadCell>
            <HeadCell>
              <b>Fuel Type</b>
            </HeadCell>
            <HeadCell>
              <b>Receipt Number</b>
            </HeadCell>
            <HeadCell>
              <b>Supplier</b>
            </HeadCell>
            <HeadCell>
              <b>Oil (L)</b>
            </HeadCell>
            <HeadCell>
              <b>Oil (R)</b>
            </HeadCell>
            <HeadCell>
              <b>Post-Flight</b>
            </HeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fuelings.map((fueling) => (
            <TableRow key={fueling.id}>
              <Cell>{formatTime(fueling.fueling_timestamp)}</Cell>
              <Cell>
                {fueling.fuel_uplift
                  ? `${fueling.fuel_uplift} ${fueling.uplift_unit || ""}`
                  : "N/A"}
              </Cell>
              <Cell>{fueling.fuel_before_uplift || "N/A"}</Cell>
              <Cell>{fueling.fuel_after_uplift || "N/A"}</Cell>
              <Cell>{fueling.density || "N/A"}</Cell>
              <Cell>{fueling.density_units || "N/A"}</Cell>
              <Cell>{fueling.fuel_type || "N/A"}</Cell>
              <Cell>{fueling.receipt_number || "N/A"}</Cell>
              <Cell>{fueling.supplier || "N/A"}</Cell>
              <Cell>{fueling.oil_l || "N/A"}</Cell>
              <Cell>{fueling.oil_r || "N/A"}</Cell>
              <Cell>
                {fueling.is_post_flight ? (
                  <CheckOutlinedIcon sx={{ color: "green" }} />
                ) : (
                  <ClearOutlinedIcon sx={{ color: "red" }} />
                )}
              </Cell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FuelingsTable;
