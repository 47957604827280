import { FC } from "react";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LockResetIcon from '@mui/icons-material/LockReset';
import FiberPinIcon from '@mui/icons-material/FiberPin';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import {
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import { useUsers } from "../../hooks/useUsers";
import { ConfirmModal, EditUserModal, HeadCell, InlineLink } from "..";

type Props = {
  operators: any[];
};

export const AdminUsersList: FC<Props> = ({ operators }) => {
  const {
    users: { data, isLoading, isSuccess },
    setIsDeleteModalOpen,
    handleOpenDeleteModal,
    isDeleteModalOpen,
    handleDeleteUser,
    setIsEditModalOpen,
    handleOpenEditModal,
    isEditModalOpen,
    setIsResetPasswordModalOpen,
    handleOpenResetPasswordModal,
    isResetPasswordModalOpen,
    handleResetPassword,
    setIsResetPinModalOpen,
    handleOpenResetPinModal,
    isResetPinModalOpen,
    handleResetPin,
    selectedUser,
  } = useUsers();
  const usersWithOperator: UserWithOperator[] = data?.map((user: User) => {
    const operator = operators?.find(op => op.id === user.operator_ref);
    return {
      ...user,
      operator: operator ? operator.name : 'Unknown',
    };
  });

  if (isLoading) {
    return (
      <Backdrop sx={{ zIndex: 1 }} open={isLoading || false}>
        <CircularProgress />
      </Backdrop>
    )
  }

  return isSuccess && usersWithOperator?.length ? (
    <>
      <Typography
        component="h1"
        variant="h4"
        sx={{ pb: 2, pt: 3 }}
      >
        Manage users
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table aria-label="Users">
          <TableHead>
            <TableRow>
              <HeadCell>Id</HeadCell>
              <HeadCell>Email</HeadCell>
              <HeadCell>TLC</HeadCell>
              <HeadCell>Operator</HeadCell>
              <HeadCell>Role</HeadCell>
              <HeadCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {usersWithOperator?.map((row) => (
              <TableRow
                key={row.email}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '&:nth-of-type(even)': { backgroundColor: '#00000008' },
                }}
              >
                <TableCell component="th" scope="row">{row.id}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.tlc}</TableCell>
                <TableCell>{row.operator}</TableCell>
                <TableCell>{row.tlc ? "smartEFB User" : row.is_admin ? "Admin" : "User"}</TableCell>
                <TableCell align="right">
                  {!!row.tlc && <Tooltip placement="top" title="Library Report">
                    <InlineLink to={`/users/${row.id}/library/report`}>
                      <LibraryBooksIcon fontSize="medium" sx={{ mr: 2, cursor: "pointer" }} />
                    </InlineLink>
                  </Tooltip>}
                  <Tooltip placement="top" title="Edit User">
                    <BorderColorIcon fontSize="medium" sx={{ mr: 2, cursor: "pointer" }} onClick={() => handleOpenEditModal(row)} />
                  </Tooltip>
                  {!!row.type && <Tooltip placement="top" title={"Reset Password"}>
                    <LockResetIcon fontSize="medium" sx={{ mr: 2, cursor: "pointer" }} onClick={() => handleOpenResetPasswordModal(row)} />
                  </Tooltip>}
                  {!!row.tlc && <Tooltip placement="top" title={"reset PIN"}>
                    <FiberPinIcon fontSize="medium" sx={{ mr: 2, cursor: "pointer" }} onClick={() => handleOpenResetPinModal(row)} />
                  </Tooltip>}
                  <Tooltip placement="top" title="Delete User">
                    <DeleteForeverIcon fontSize="medium" sx={{ mr: 2, cursor: "pointer" }} onClick={() => handleOpenDeleteModal(row)} />
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmModal
        isOpen={isDeleteModalOpen}
        text={`Are you sure you want to delete user ${selectedUser.email}?`}
        onSubmit={handleDeleteUser}
        onClose={() => setIsDeleteModalOpen(false)}
      />
      <ConfirmModal
        isOpen={isResetPasswordModalOpen}
        text={`Are you sure you want to reset password for user ${selectedUser.email}?`}
        onSubmit={handleResetPassword}
        onClose={() => setIsResetPasswordModalOpen(false)}
      />
      <ConfirmModal
        isOpen={isResetPinModalOpen}
        text={`Are you sure you want to reset PIN for user ${selectedUser.email}?`}
        onSubmit={handleResetPin}
        onClose={() => setIsResetPinModalOpen(false)}
      />
      <EditUserModal
        isOpen={isEditModalOpen}
        user={selectedUser}
        onClose={() => setIsEditModalOpen(false)}
      />
    </>
  ) : <Typography>No users found</Typography>;
};
