import { FunctionComponent } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import { Cell, HeadCell } from "../../";

const CrewsTable: FunctionComponent<{ crews: Crew[] }> = ({ crews }) => {
  return (
    <TableContainer component={Paper} sx={{ marginBottom: "20px" }}>
      <Table aria-label="Crew Table">
        <TableHead>
          <TableRow>
            <HeadCell>
              <b>TLC</b>
            </HeadCell>
            <HeadCell>
              <b>Rank</b>
            </HeadCell>
            <HeadCell>
              <b>Pilot Takeoff</b>
            </HeadCell>
            <HeadCell>
              <b>Pilot In Command</b>
            </HeadCell>
            <HeadCell>
              <b>Pilot Landing</b>
            </HeadCell>
            <HeadCell>
              <b>Position</b>
            </HeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {crews.map((crew) => (
            <TableRow key={crew.id}>
              <Cell>{crew.tlc || "N/A"}</Cell>
              <Cell>{crew.rank || "N/A"}</Cell>
              <Cell>
                {crew.pilot_takeoff ? (
                  <CheckOutlinedIcon sx={{ color: "green" }} />
                ) : (
                  <ClearOutlinedIcon sx={{ color: "red" }} />
                )}
              </Cell>
              <Cell>
                {crew.pilot_in_command ? (
                  <CheckOutlinedIcon sx={{ color: "green" }} />
                ) : (
                  <ClearOutlinedIcon sx={{ color: "red" }} />
                )}
              </Cell>
              <Cell>
                {crew.pilot_landing ? (
                  <CheckOutlinedIcon sx={{ color: "green" }} />
                ) : (
                  <ClearOutlinedIcon sx={{ color: "red" }} />
                )}
              </Cell>
              <Cell>{crew.position || "N/A"}</Cell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CrewsTable;
