import { FunctionComponent, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";

import FlightOutlinedIcon from '@mui/icons-material/FlightOutlined';
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import {
  Page,
  Heading,
  InfoTable,
  FuelChecksTable,
  WaypointsTable,
  TimeTable,
  FuelTable,
  FilesList,
  Map,
  CusotmIconButton,
  ButtonText,
  DeleteFlightModal,
  JourneyLogModal,
} from "../components";
import { useGetFlight } from "../hooks/useFlight";

const Flight: FunctionComponent = () => {
  const { id } = useParams();
  const [activeMarker, setActiveMarker] = useState("");
  const [isJLModalOpen, setIsJLModalOpen] = useState(false);
  const [deleteID, setDeleteId] = useState('');
  const { data, isLoading, isSuccess, isError } = useGetFlight(id || "");
  const {
    waypoints,
    gpsTrack,
    supplementary,
    time,
    weight,
    altn,
    fuel,
    fueling,
    approach,
    deicing,
    delay,
    crewInfo
  } = data || {};
  const fuelChecks = waypoints?.filter(({ isAfqSet }: Waypoint) => !!isAfqSet);
  const componentRef = useRef<HTMLInputElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Flight ${id} Details`,
    pageStyle: "padding: 20px",
  });

  const handleOpenDeleteModal = () => {
    setDeleteId(id || '');
  }

  const handleCloseDeleteModal = () => setDeleteId('');

  const handleToggleJLModal = () => {
    setIsJLModalOpen(!isJLModalOpen);
  }

  const handleActiveMarker = (marker: string) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  return (
    <>
      <Container>
        <Backdrop sx={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress />
        </Backdrop>
        <Page>
          {isError && (
            <Heading variant="h3" component="p">
              Flight {id} not found
            </Heading>
          )}
          {isSuccess && (
            <>
              <Heading variant="h4" component="h1" lineHeight={1.6}>
                Flight: <Typography
                  sx={{ display: "inline", mr: "10px" }}
                  component="span"
                  variant="h4"
                  color="text.primary"
                >{id}</Typography>
                <Box sx={{ display: "inline-block" }}>
                  <CusotmIconButton
                    size="large"
                    variant="outlined"
                    onClick={handleToggleJLModal}
                    startIcon={<FlightOutlinedIcon />}
                  >
                    <ButtonText>Journey Log</ButtonText>
                  </CusotmIconButton>
                  <CusotmIconButton
                    size="large"
                    variant="outlined"
                    onClick={handlePrint}
                    startIcon={<PrintOutlinedIcon />}
                  >
                    <ButtonText>Print</ButtonText>
                  </CusotmIconButton>
                  <CusotmIconButton
                    size="large"
                    variant="outlined"
                    onClick={handleOpenDeleteModal}
                    startIcon={<DeleteOutlineIcon />}
                  >
                    <ButtonText>Delete</ButtonText>
                  </CusotmIconButton>
                </Box>
              </Heading>
              <Grid container spacing={2} ref={componentRef}>
                <Grid item md={4} xs={12}>
                  <InfoTable {...supplementary} />
                  <TimeTable {...time} {...supplementary} />
                  <Typography
                    variant="body2"
                    sx={{
                      paddingTop: "12px",
                      paddingBottom: "12px",
                    }}
                  >
                    <b>Signed By: {supplementary?.user_tlc}</b>
                  </Typography>
                  <FuelTable {...weight} {...fuel} altn={altn} />
                  <FilesList />
                </Grid>
                <Grid item md={8} xs={12}>
                  <Map
                    waypoints={waypoints}
                    gpsTrack={gpsTrack}
                    onMarkerClick={handleActiveMarker}
                    activeMarker={activeMarker}
                  />
                  <FuelChecksTable
                    fuelChecks={fuelChecks}
                    onClick={handleActiveMarker}
                  />
                  <WaypointsTable
                    waypoints={waypoints}
                    onClick={handleActiveMarker}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Page>
        <DeleteFlightModal id={deleteID} onClose={handleCloseDeleteModal} />
        <JourneyLogModal
          isOpen={isJLModalOpen}
          onClose={handleToggleJLModal}
          fuelings={fueling}
          approaches={approach}
          deicings={deicing}
          delays={delay}
          crews={crewInfo}
        />
      </Container>
    </>
  );
};

export default Flight;
