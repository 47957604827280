import { FunctionComponent } from "react";
import {
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import FuelingsTable from "./FuelingsTable";
import ApproachesTable from "./ApproachesTable";
import DeicingsTable from "./DeicingsTable";
import DelaysTable from "./DelaysTable";
import CrewsTable from "./CrewsTable";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  fuelings: Fueling[];
  approaches: Approach[];
  deicings: Deicing[];
  delays: Delay[];
  crews: Crew[];
};

export const JourneyLogModal: FunctionComponent<Props> = ({ isOpen, onClose, fuelings, approaches, deicings, delays, crews }) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="dialog-title"
      open={isOpen}
      maxWidth={"lg"}
      fullWidth
      scroll={"body"}
    >
      <DialogTitle id="dialog-title" variant="h4">
        Journey Log
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: "#000",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography variant="h5" mb="10px">Fuelings: {fuelings?.length ? "" : "N/A"}</Typography>
        {!!fuelings?.length && <FuelingsTable fuelings={fuelings} />}
        <Typography variant="h5" mb="10px">Approaches: {approaches?.length ? "" : "N/A"}</Typography>
        {!!approaches?.length && <ApproachesTable approaches={approaches}/>}
        <Typography variant="h5" mb="10px">Deicings: {deicings?.length ? "" : "N/A"}</Typography>
        {!!deicings?.length && <DeicingsTable deicings={deicings} />}
        <Typography variant="h5" mb="10px">Delays: {delays?.length ? "" : "N/A"}</Typography>
        {!!delays?.length && <DelaysTable delays={delays} />}
        <Typography variant="h5" mb="10px">Crews: {crews?.length ? "" : "N/A"}</Typography>
        {!!crews?.length && <CrewsTable crews={crews} />}
      </DialogContent>
    </Dialog>
  );
};
